import React from "react";
import abseglogo from "../../assets/imgs/abseg.jpg";
import logo from "../../assets/imgs/logo001.png";
import logo2 from "../../assets/imgs/logobranca.png";
import svg1 from "../../assets/imgs/ic_baseline-facebook.svg";
import svg2 from "../../assets/imgs/mdi_linkedin.svg";
import svg3 from "../../assets/imgs/mdi_instagram.svg";
import svg4 from "../../assets/imgs/mdi_youtube.svg";
import svg5 from "../../assets/imgs/ic_baseline-facebookw.svg";
import svg6 from "../../assets/imgs/mdi_linkedinw.svg";
import svg7 from "../../assets/imgs/mdi_instagramw.svg";
import svg8 from "../../assets/imgs/mdi_youtubew.svg";

const footer = () => {
  return (
    <div>
      <div className="block sm:hidden">
        <div className="bg-white p-6 pt-24">
          {/* Logo and Quote Section */}
          <div className="text-center">
            <img
              src={logo}
              alt="ADX Company Logo"
              className="mx-auto mb-4 h-10"
            />
            <p className="text-black text-sm mb-2">
              "Nós só podemos ver um pouco<br></br>do futuro, mas o suficiente
              para<br></br>saber que há muito a fazer"
            </p>
            <p className="text-gray-600 mb-6">- Alan Turing</p>
          </div>

          {/* Navigation Links */}


          {/* Address Section */}
          <div className="text-center mb-6">
            <h2 className="text-lg font-semibold mb-2">Onde Estamos</h2>
            <p className="text-gray-700">
            Condomínio Empresarial São Paulo, Avenida Rio Branco nº243, Sala 301, Recife, Recife-PE, CEP 50030-310
            </p>
          </div>

          {/* Social Media Icons */}
          <div className="text-center mb-6">
            <div className="flex justify-center space-x-4">
              <a
                href="https://www.linkedin.com/company/adxcompany/?viewAsMember=true"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={svg3} alt="LinkedIn" className="w-6 h-6" />
              </a>
              <a
                href="https://www.instagram.com/adx.company_/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={svg2} alt="Instagram" className="w-6 h-6" />
              </a>
              <a
                href="https://www.youtube.com/@adxcompany "
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={svg4} alt="YouTube" className="w-6 h-6" />
              </a>
            </div>
          </div>

          {/* Association Certification */}
          <div className="text-center">
            <p className="text-gray-700 mb-2 text-sm">
              Associações e Certificações de<br></br>Excelência
            </p>
            <img src={abseglogo} alt="ABSEG" className="mx-auto mt-4" />
          </div>
        </div>
        <p className="bg-customPurple text-footer py-4 text-white">
          Copyright © Todos os direitos reservados. ADX Tech Ltda.<br></br>
          Desenvolvimento de Softwares LTDA, CNPJ nº  53.209.053/0001-83
        </p>
      </div>

      <div className="bg-customPurple pt-20 pb-10 hidden sm:block text-white">
        <div className="items-top grid grid-cols-3 mx-auto gap-20 md:max-w-[90vw] text-left pb-12">
          <div>
            <div className="flex flex-col">
              <img
                src={logo2}
                alt="ABSEG"
                className="w-40 self-start mt-4 pb-4"
              />
              <p>
                “Nós só podemos ver um pouco do futuro, mas o suficiente para
                saber que há muito a fazer”
                <br />
                <br />
                - Alan Turing
              </p>
            </div>
          </div>
          <div>
            <div className="flex flex-col">
              <p className="font-bold pb-4">Onde estamos</p>
              <p>
              Condomínio Empresarial São Paulo, Avenida Rio Branco nº243, Sala 301, Recife, Recife-PE, CEP 50030-310
              </p>
            </div>
            <div className="text-center mb-6">
            <div className="flex justify-left pt-2 space-x-4">
    
              <a
                href="https://www.linkedin.com/company/adxcompany/?viewAsMember=true"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={svg6} alt="LinkedIn" className="w-6 h-6" />
              </a>
              <a
                href="https://www.instagram.com/adx.company_/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={svg7} alt="Instagram" className="w-6 h-6" />
              </a>
              <a
                href="https://www.youtube.com/@adxcompany"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={svg8} alt="YouTube" className="w-6 h-6" />
              </a>
            </div>
          </div>
          </div>
          <div className="flex flex-col">
            <p className="font-bold">
              Associações e Certificados <br /> de Excelência
            </p>
            <img
              src={abseglogo}
              alt="ABSEG"
              className="h-20 w-auto self-start mt-4"
            />
          </div>
        </div>
        <p className="pt-7">
          Copyright © Todos os direitos reservados. ADX Tech Ltda.
          <br /> Desenvolvimento de Softwares LTDA, CNPJ nº  53.209.053/0001-83
        </p>
      </div>
    </div>
  );
};

export default footer;
